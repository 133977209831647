import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { navigate } from 'gatsby';
import { grey3, grey4, grey5, grey6 } from '../../lib/components/bedrock/SwColors';
import { GradientTypes } from '../../lib/enums/GradientTypes';
import { getGradientCss } from '../../lib/helpers/color';
import { getImageUrl } from '../../lib/helpers/image';
import SwLine from './SwLine';
import SwVerticalSpacer from './SwVerticalSpacer';
import SwEditorialTypography from './typography/SwEditorialTypography';

const StyledImg = styled('img')<{ minicard: string }>`
    height: ${(props) => (props.minicard === 'true' ? '172px' : '200px')};
    width: ${(props) => (props.minicard === 'true' ? '250px' : '290px')};
    object-fit: cover;
    border: solid 1px ${grey5};
    border-radius: 8px;
`;

const CardImageGrid = styled(Grid)`
    &.withLink {
        transition: 0.5;
        opacity: 100%;
        -webkit-transition: opacity 0.25s ease-out;
        -moz-transition: opacity 0.25s ease-out;
        -o-transition: opacity 0.25s ease-out;
        transition: opacity 0.25s ease-out;

        &:hover {
            cursor: pointer;
            opacity: 75%;
            transition: 0.5;

            h4 {
                color: ${grey3} !important;
            }

            p,
            body2 {
                color: ${grey4} !important;
            }
        }
    }
`;

const StyledGrid = styled(Grid)<{ minicard: string }>`
    align-items: ${(props) => (props.minicard === 'true' ? 'flex-start' : 'center')};
    flex-direction: column;
    text-align: ${(props) => (props.minicard === 'true' ? 'left' : 'center')};
`;

const StyledBox = styled(Box)<{ minicard: string }>`
    & > div {
        justify-content: ${(props) => (props.minicard === 'true' ? 'flex-start' : 'center')};
    }
`;

const StyledBox2 = styled(Box)<{ minicard: string }>`
    height: ${(props) => (props.minicard === 'true' ? '104px' : '100%')};
`;

const StyledBox3 = styled(Box)<{ minicard: string }>`
    height: ${(props) => (props.minicard === 'true' ? '172px' : '200px')};
    width: ${(props) => (props.minicard === 'true' ? '250px' : '290px')};
`;

const GradientBox = styled(Box)<{ minicard: string }>`
    background: ${() => getGradientCss(GradientTypes.RANDOM, true)};
    height: ${(props) => (props.minicard === 'true' ? '172px' : '200px')};
    width: ${(props) => (props.minicard === 'true' ? '250px' : '290px')};
`;

const SwCardImage = (props: SwCardImageProps) => {
    return (
        <CardImageGrid
            className={!props.noLink ? 'withLink' : ''}
            item={true}
            xs={12}
            onClick={() => navigate(props.urlToOpen)}
        >
            <StyledGrid container={true} minicard={props.miniCard.toString()}>
                <Grid item={true}>
                    <StyledBox3 minicard={props.miniCard.toString()}>
                        {props.imageSrc === '' ? (
                            <GradientBox minicard={props.miniCard.toString()} title={props.title} />
                        ) : (
                            <StyledImg
                                alt={props.title}
                                minicard={props.miniCard.toString()}
                                src={getImageUrl(props.imageSrc, 600)}
                            />
                        )}
                    </StyledBox3>
                </Grid>
                <Grid item={true} xs={12}>
                    <SwVerticalSpacer spacing={props.miniCard ? 2 : 4} />
                </Grid>
                {!props.miniCard && (
                    <>
                        <Grid item={true} xs={12}>
                            <SwEditorialTypography
                                color={props.color}
                                ellipsis={true}
                                isEditorial={props.isEditorial}
                                variant={'body2'}
                            >
                                {props.headline}
                            </SwEditorialTypography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SwVerticalSpacer spacing={2} />
                        </Grid>
                    </>
                )}
                <Grid item={true} xs={props.miniCard ? 12 : 10}>
                    <StyledBox2 minicard={props.miniCard.toString()}>
                        <SwEditorialTypography
                            color={props.color}
                            ellipsis={true}
                            isEditorial={props.isEditorial}
                            variant={'h4'}
                        >
                            {props.title}
                        </SwEditorialTypography>
                        <SwVerticalSpacer spacing={props.miniCard ? 1 : 2} />
                        <Typography style={{ color: props.color }} variant={'body2'}>
                            {props.subtitle}
                        </Typography>
                    </StyledBox2>
                </Grid>
            </StyledGrid>
            <StyledBox minicard={props.miniCard.toString()}>
                <SwVerticalSpacer spacing={1} />
                {props.children && props.miniCard && <SwLine color={grey6} spacing={1} />}
                {props.children}
            </StyledBox>
        </CardImageGrid>
    );
};

interface SwCardImageProps {
    imageSrc: string;
    title: string;
    subtitle?: string;
    urlToOpen?: string;
    children?: any;
    isEditorial: boolean;
    miniCard: boolean;
    color: string;
    noLink?: boolean;
    headline?: string;
}

export default SwCardImage;
