import { ObjectTypes } from '../enums/ObjectTypes';

export function getElementTitle(element: any) {
    switch (element.objectType) {
        case ObjectTypes.ARTICLE:
            return element.title;
        case ObjectTypes.PROJECT:
        case ObjectTypes.COMPANY:
            return element.name;
    }
}

export function getElementImgSrc(element) {
    switch (element.objectType) {
        case ObjectTypes.ARTICLE:
        case ObjectTypes.PROJECT:
            return element.heroImages[0];
        case ObjectTypes.COMPANY:
            return element.logo;
    }
}

export function getElementSubtitle(element) {
    switch (element.objectType) {
        case ObjectTypes.ARTICLE:
            return element.abstract;
        case ObjectTypes.PROJECT:
            return element.shortDescription;
        case ObjectTypes.COMPANY:
            return element.name;
    }
}

export function getElementURL(element) {
    switch (element.objectType) {
        case ObjectTypes.ARTICLE:
            return `/blog/${element.slug}`;
        case ObjectTypes.PROJECT: {
            return `/catalogue/${element.slug}`;
        }
        case ObjectTypes.COMPANY:
            return `/company/${element.slug}`;
    }
}

export function getElementHeadline(element) {
    switch (element.objectType) {
        case ObjectTypes.ARTICLE:
            return element.category.label;
        case ObjectTypes.PROJECT:
            return element.company.name;
        case ObjectTypes.COMPANY:
            return null;
    }
}
