import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import SwContainer from '../components/v1/SwContainer';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwVerticalSpacer from '../components/v1/SwVerticalSpacer';
import SectionProjectsCatalogue from '../components/v1/projects/SectionProjectsCatalogue';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';

const StyledGrid = styled(Grid)`
    height: ${({ theme }) => theme.spacing(26)};
`;

const CatalogueIndex = ({ pageContext: { projects }, data }) => {
    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.catalogue.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />

            <SwLayout background={grey6}>
                <SwContainer background={grey6} withMaxWidth={true}>
                    <StyledGrid alignItems={'center'} container={true}>
                        <Grid item={true} lg={4} xs={12}>
                            <Typography component={'h1'} variant={'h2'}>
                                Catalogue
                            </Typography>
                        </Grid>
                        <Grid item={true} lg={8} xs={12}>
                            <Grid container={true}>
                                <Grid item={true} xs={12}>
                                    <Typography component={'p'} variant={'h4'}>
                                        The way you contribute
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Typography component={'p'} style={{ color: grey3 }} variant={'h4'}>
                                        says a lot about you
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledGrid>
                </SwContainer>
                <SwContainer background={grey6} withMaxWidth={true}>
                    <Grid container={true}>
                        <Grid item={true} xs={12}>
                            <SwLine color={grey5} spacing={0} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SwVerticalSpacer spacing={4} />
                        </Grid>
                    </Grid>
                </SwContainer>
                <SwContainer background={grey6} withMaxWidth={true}>
                    <SectionProjectsCatalogue projects={projects} />
                </SwContainer>

                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = () => (
    <Seo
        description={'The way you contribute says a lot about you'}
        pathname={`/catalogue2`}
        title={'Sweep Blog Catalogue'}
    />
);

export const query = graphql`
    {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: en } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: en }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
    }
`;

export default CatalogueIndex;
