import React, { Fragment } from 'react';
import { Grid, styled } from '@mui/material';
import clsx from 'clsx';
import { black, grey5 } from '../../lib/components/bedrock/SwColors';
import {
    getElementURL,
    getElementImgSrc,
    getElementTitle,
    getElementSubtitle,
    getElementHeadline
} from '../../lib/helpers/dataElement';
import SwCardImage from './SwCardImage';
import SwLine from './SwLine';

const StyledGrid = styled(Grid)`
    &.with-left-line {
        border-left: ${() => grey5} 1px solid;
    }
    &.with-right-line {
        border-right: ${() => grey5} 1px solid;
    }
`;

const StyledLink = styled('a')`
    color: inherit;
    text-decoration: inherit;
`;

const SwRowCard = ({
    totalNumberElements,
    isEditorial,
    elements,
    miniCard,
    numberOfCardPerRow,
    reference
}: SwRowCard) => {
    return (
        <>
            <Grid container={true}>
                {elements.map((element, index) => {
                    return (
                        <Fragment key={index}>
                            <StyledGrid
                                className={clsx(
                                    index === 1 && numberOfCardPerRow > 1 && 'with-left-line',
                                    (index + 1) % numberOfCardPerRow !== 0 &&
                                        numberOfCardPerRow > 1 &&
                                        'with-right-line'
                                )}
                                item={true}
                                lg={4}
                                md={6}
                                ref={reference.current[index]}
                                xs={12}
                            >
                                <StyledLink href={getElementURL(element)}>
                                    <SwCardImage
                                        color={black}
                                        headline={getElementHeadline(element)}
                                        imageSrc={getElementImgSrc(element)}
                                        isEditorial={isEditorial}
                                        miniCard={miniCard}
                                        subtitle={getElementSubtitle(element)}
                                        title={getElementTitle(element)}
                                        urlToOpen={getElementURL(element)}
                                    />
                                </StyledLink>
                            </StyledGrid>

                            {(index + 1) % numberOfCardPerRow === 0 && index + 1 < totalNumberElements ? (
                                <Grid container={true} item={true} xs={12}>
                                    <SwLine color={grey5} spacing={4} />
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Fragment>
                    );
                })}
            </Grid>
        </>
    );
};

interface SwRowCard {
    totalNumberElements: number;
    reference: any;
    isEditorial: boolean;
    elements: any[];
    miniCard: boolean;
    numberOfCardPerRow: number;
}

export default SwRowCard;
