import React from 'react';
import { Grid, styled } from '@mui/material';
import SwButton, { SwButtonKind } from '@lib/components/bedrock/button/SwButton';
import ChevronDownBlack from '../../assets/icons/chevron-down-black.svg';

const StyledImg = styled('img')`
    padding-left: 10px;
`;

const StyledGrid = styled(Grid)`
    @media (max-width: 960px) {
        text-align: center;
    }
`;

interface SwMoreButtonProps {
    btnName: string;
    onClick: (event) => void;
}

const SwMoreButton = (props: SwMoreButtonProps) => {
    return (
        <Grid container={true}>
            <StyledGrid item={true} xs={12}>
                <SwButton ariaLabel={props.btnName} kind={SwButtonKind.Secondary} onClick={props.onClick}>
                    {props.btnName}
                    <StyledImg alt={props.btnName} src={ChevronDownBlack} />
                </SwButton>
            </StyledGrid>
        </Grid>
    );
};

export default SwMoreButton;
