import React, { useState, createRef, useRef } from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { Elements } from '../../lib/types/element';
import SwMoreButton from './SwMoreButton';
import SwRowCard from './SwRowCard';

const SwRowsWithLargeCards = ({ elements, isEditorial, plusButtonName }: SwRowsWithLargeCardsProps) => {
    const [numberElementsLoaded, setNumberElementsLoaded] = useState(12);

    const sm = useMediaQuery(useTheme().breakpoints.down('md'));
    const md = useMediaQuery(useTheme().breakpoints.down('lg'));

    const numberOfCardPerRow = sm ? 1 : md ? 2 : 3;
    const totalNumberElements = elements.length;

    const loadMore = () => {
        setNumberElementsLoaded((prevState) => prevState + 12);

        setTimeout(() => {
            refs.current[numberElementsLoaded + 1].current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    };

    const refs = useRef(elements.all.map(() => createRef()));

    return (
        <Grid container={true} item={true} xs={12}>
            <>
                <SwRowCard
                    elements={elements.all.slice(0, numberElementsLoaded)}
                    isEditorial={isEditorial}
                    miniCard={false}
                    numberOfCardPerRow={numberOfCardPerRow}
                    reference={refs}
                    totalNumberElements={totalNumberElements}
                />

                {numberElementsLoaded < totalNumberElements && (
                    <SwMoreButton btnName={plusButtonName} onClick={loadMore} />
                )}
            </>
        </Grid>
    );
};

interface SwRowsWithLargeCardsProps {
    plusButtonName: string;
    elements: Elements;
    isEditorial: boolean;
}

export default SwRowsWithLargeCards;
