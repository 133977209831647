import Article from './article';
import { Company } from './company';
import Project from './project';

export class Elements {
    private articles: Article[];
    private projects: Project[];
    private companies: Company[];

    constructor(articles: Article[], projects: Project[], companies: Company[]) {
        this.articles = articles || [];
        this.projects = projects || [];
        this.companies = companies || [];
    }

    get length(): number {
        return this.articles.length + this.projects.length + this.companies.length;
    }

    get empty(): boolean {
        return this.length === 0;
    }

    get all(): any {
        return this.articles.concat(this.projects.concat(this.companies as any) as any);
    }

    public static fromJSON(obj: any): Elements {
        return new Elements(obj.articles, obj.projects, obj.companies);
    }
}
