import React from 'react';
import { Grid } from '@mui/material';
import { Elements } from '../../../lib/types/element';
import Project from '../../../lib/types/project';
import SwRowsWithLargeCards from '../SwRowsWithLargeCards';

const SectionProjectsCatalogue = (props: SectionProjectsCatalogueProps) => {
    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <SwRowsWithLargeCards
                    elements={new Elements(null, props.projects, null)}
                    isEditorial={false}
                    plusButtonName={'Load more projects'}
                />
            </Grid>
        </Grid>
    );
};

interface SectionProjectsCatalogueProps {
    projects: Project[];
}

export default SectionProjectsCatalogue;
